









































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab1Question2',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        answer1: null,
        language: 'en',
      },
      rules: [(v: string) => v?.length <= 1000 || 'Max 1000 characters'],
      questions: [
        {
          en: 'How does increasing the polarity of the solvent system affect the results of a TLC?',
          fr: 'Quel effet a l’augmentation de la polarité sur une CCM ?',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
